import {
	$dqs,
	getCarouselEmbedEle,
	getSettingsStyle,
	getStars,
	getSvg,
	v,
} from "../swiper-reviews";

export async function setPhotoCarousel(settings, index) {
	if ($dqs('#tt-carousel[data-type="photo"]')) {
		return;
	}
	const { swiperStyle, lang } = v;
	const column = v.columns[index];

	const target = getCarouselEmbedEle(
		"#trustoo-photo-carousel",
		settings.is_home_active,
		settings.is_product_active
	);
	if (!target) {
		return;
	}

	const activationVal = 1,
		all = 1,
		feature = 2,
		productReviews = 5;

	const params = { has_resource: activationVal };
	if (settings.show_reviews === all) {
		params.is_show_all = activationVal;
	} else if (settings.show_reviews === feature) {
		params.is_feature = activationVal;
	} else if (settings.show_reviews === productReviews) {
		if (v.productId) {
			params.product_id = v.productId;
		} else {
			params.is_show_all = activationVal;
		}
	}
	const revRed = await v.request("getReviews", params);
	let reviewsList = null,
		ratingData = null;
	if (revRed[0]) {
		return;
	} else {
		if (revRed[1].list.length === 0) {
			return;
		}
		reviewsList = revRed[1].list;
		ratingData = revRed[1].total_rating;
	}
	v.reviewsLists[index] = reviewsList;
	const cardCount = reviewsList.length;
	let reviewsHtml = "";
	const s = settings;
	swiperStyle.textContent += `
	#tt-carousel[data-type=photo]{
		--btn-bg-color: ${s.scroll_bg_color};
		--title-color: ${s.title_color};
		--desc-color: ${s.description_color};
		--star-bg-color:${s.stars_bg_card_color || "#fff"};
		--block-bg-color:${s.block_bg_color || "#fff"};
		--card-radius:${s.card_border_radius}px;
	}
	#tt-carousel[data-type=photo] .resource-list{
		height: ${204 * (s.photo_ratio == 1 ? 1 : 1.33)}px
	}
	${getSettingsStyle(s, "photo")}
	`;

	const btnColor = settings.scroll_buttons_icon_color;
	reviewsList.forEach((it, inx) => {
		let verifiedHtml = "";
		if (settings.is_show_verified_purchase === 1 && it.verified_badge === 1) {
			verifiedHtml = v.badgeIconSvg;
		}
		const resource = it.resources[0];
		if (!resource) {
			return;
		}
		const imgSrc =
			resource.resource_type === 2 ? resource.thumb_src : resource.src;
		const videoIcon = resource.resource_type === 2 ? getSvg("video") : "";
		const imagesHtml = `<div class="resource-list" style="width: 100%; overflow: hidden;"><div class="resource-item tt-reviews-image" style="width: 100%; overflow: hidden;display: flex;justify-content: center;align-items: center;">
		<img index="${index}"  alt="${index}"
		 data-original="${imgSrc}" src="${imgSrc}" />
		 ${videoIcon}
		</div></div>`;

		reviewsHtml += `
		<div class="swiper-slide">
    <div class="tt-card-wrapper" data-country="${
			it.author_country
		}" data-date="${it.commented_at}"> 
		<div class="tt-card new" no="${inx}"> 
		${imagesHtml} <div class="tt-card-bottom">
		 <div class="tt-card-rating"> <div class="vstar-star${
				v.isSpecialRatingIcon ? " special" : " normal"
			}">${getStars(it.star, v)}</div> </div>
			 <div> <div class="tt-card-author"> <div style="height:26px;display:block">${
					it.author
				}</div> ${it.verified_badge === 1 ? verifiedHtml : ""} </div>
		 <div class="tt-card-review"> ${it.content} </div> </div>
		 </div> </div> </div></div>
    `;
	});
	let descHtml = "";

	if (settings.is_show_description === 1) {
		const numberContent = `<span id="tt-reviews-num" ${
			settings.click_to_view_option === 1 ? "data-total-reviews" : ""
		}>${ratingData.total_reviews}</span>`;
		const descText = lang.card_carousel.photo.desc
			.replace("{{total_rating}}", ratingData.rating)
			.replace("{{total_reviews}}", numberContent);
		descHtml = `<div id="tt-carousel-desc">${descText}</div>`;
	}
	target.insertAdjacentHTML(
		"beforeend",
		`<div id="tt-carousel" data-type="photo" class="tt-swiper column-${column} pending">
    <div class="tt-swiper-wrapper">
    <div id="tt-carousel-content">
		${
			settings.is_show_title === 1
				? `<div id="tt-carousel-title">${lang.card_carousel.photo.title}</div>`
				: ""
		}
		${descHtml}
		<div id="tt-carousel-list-wrapper" style="position:relative">
			<div style="overflow: hidden;padding: 0 10px;">
				<div id="tt-carousel-list" data-index="${index}" class="tt-card-list">
				<div class="tt-swiper-container swiper-wrapper"
				navigation="false"
				space-between="20"
				loop="true"
				slides-per-view="${column}"
				${v.isSetTimer ? `autoplay autoplay-pause-on-mouse-enter="true"` : ""}
				data-index="${index}"
				data-count="${cardCount}"
			>
      ${reviewsHtml}
			</div>
      </div>
      </div>
			${
				cardCount > v.columns[index]
					? `<div class="tt-swiper-pre tt-swiper-btn">${getSvg(
							"scrollBtn",
							btnColor
					  )}</div>
			<div class="tt-swiper-next tt-swiper-btn">${getSvg(
				"scrollBtn",
				btnColor
			)}</div>`
					: ""
			}
    </div>
    </div>
  </div>
    </div>
  `
	);
}
