import { setBarrageCarousel } from "./carousel/barrage";
import { setPhotoCarousel } from "./carousel/photo";
import { setPrimaryCarousel } from "./carousel/primary";
import { setTextCarousel } from "./carousel/text";

// if (module.hot) {
// 	module.hot.accept("./carousel/photo", () => {});
// }
const primaryType = 1,
	textType = 2,
	photoType = 3,
	barrageType = 4;
const toLeftType = 1,
	toRightType = 2;
let cardWidth = 0;
let commonHigh = 0;
let timer = null;
let sd = {},
	settingData = {};
let clickedSwiper = null;
const carouselType = sd.carousel_type;
const canMoveType = carouselType !== barrageType;
const duration = carouselType === 4 ? 3000 : 500;
let column = 3;
let otherColumn = 3;
let shop_id;
const isMobile = checkMobile();
let currentTranslateX = 0;
let cardCount = 0;
let pageType = "";
// ## 声明全局对象
export const v = {
	isHomePage: false,
	isProPage: false,
	userSetting: {},
	isMobile,
	isMixReview: true,
	revDetailInfo: { page: 0, imageIndex: 0 },
	reviewsLists: [null, null, null, null],
	ratingData: null,
	env: ENV,
	staticBaseUrl: STATIC_BASE_URL,
	constructTime: new Date(TIME).getTime() / 1000,
	source: "carousel",
	sortType: "image-descending",
	isSetTimer: true,
	ratingIconSvg: "",
	badgeIconSvg: "",
	customStyleCode: "",
	lang: null,
	translateX: [0, 0, 0, 0],
	tailX: [0, 0, 0, 0],
	columns: [0, 0, 0, 0],
	cardWidths: [0, 0, 0, 0],
	timers: [null, null, null, null],
	request: null,
	canOtherPageExecute: false,
	detailCloseCB: null,
	themeId: typeof Shopify !== "undefined" && Shopify.theme && Shopify.theme.id,
	isProductReviews: false, //产品页的轮播是否显示对应产品的评论,
	normalSpeed: 300,
	barrageSpeed: 5000,
	pageType: "",
	productId: null,
};
(async function () {
	// if (!target || (target && target.getAttribute("filled") !== null)) {
	// 	return;
	// } else {
	// 	target.setAttribute("filled", true);
	// }
	if (window.TrustooCarouselExecuted) {
		return;
	} else {
		window.TrustooCarouselExecuted = true;
	}

	document.head.insertAdjacentHTML(
		"beforeend",
		'<style id="tt-swiper-style"></style>'
	);
	const swiperStyle = $dqs("#tt-swiper-style");
	v.swiperStyle = swiperStyle;

	const carouselScriptBlk = $dqs("#trustoo-carousel-script");
	if (window.ShopifyAnalytics) {
		pageType =
			window.ShopifyAnalytics && window.ShopifyAnalytics.meta.page.pageType;
		shop_id =
			window.ShopifyAnalytics &&
			ShopifyAnalytics.lib &&
			ShopifyAnalytics.lib.config &&
			ShopifyAnalytics.lib.config.Trekkie.defaultAttributes.shopId;
	}

	if (!pageType) {
		pageType = carouselScriptBlk.dataset.page;
		if (pageType === "index") {
			pageType = "home";
		}
	}
	v.isHomePage = pageType === "index" || pageType === "home";
	v.isProPage = pageType === "product";
	v.pageType = pageType;

	// 运行前执行用户需求自定义函数
	if (typeof trustooBeforeExecute !== "undefined") {
		trustooBeforeExecute(v, {});
	} else {
		setTimeout(() => {
			if (typeof trustooBeforeExecute !== "undefined") {
				trustooBeforeExecute(v, {});
			}
		}, 200);
	}

	if (!shop_id) {
		if (carouselScriptBlk) {
			shop_id = carouselScriptBlk.dataset.shop_id;
		}
	}

	// 旧的逻辑，有此元素就可以执行程序
	if (
		$dqs(
			"#trustoo-card-carousel-widget,#trustoo-text-carousel,#trustoo-photo-carousel,#trustoo-barrage-carousel"
		)
	) {
		v.canOtherPageExecute = true;
	}

	if (!(v.isHomePage || v.isProPage || v.canOtherPageExecute)) {
		swiperStyle.remove();
		return;
	}

	const requestOption = {
		getSetting: {
			url: "get_card_carousel_settings",
			params: {
				shop_id,
			},
		},
		getLang: {
			url: "get_customer_self_define_lang",
			params: {
				shop_id,
				lang: "en",
			},
		},
		getReviews: {
			url: "get_product_reviews",
			params: {
				shop_id,
			},
		},
	};

	let res = await request("getSetting");

	if (typeof vstar_review_settings !== "undefined") {
		Object.assign(v.userSetting, vstar_review_settings);
	}

	v.settings = res[1];
	sd = res[1][0];
	settingData = sd;
	if (!sd) {
		return;
	}

	const settingsActive = v.settings.some(
		it =>
			(v.isHomePage && it.is_home_active === 1) ||
			(v.isProPage && it.is_product_active === 1)
	);
	const themeBlock = document.querySelector(
		"#trustoo-card-carousel-widget,#trustoo-photo-carousel,#trustoo-text-carousel,#trustoo-barrage-carousel"
	);
	if (!settingsActive && !themeBlock && !v.canOtherPageExecute) {
		return;
	}
	const productId = carouselScriptBlk.dataset.productId;
	if (Number(productId)) {
		v.productId = productId;
	}

	arrangeColumn();

	const dateType = sd.review_date_format_type;
	v.userSetting.review_date_format_type = dateType;
	v.isSpecialRatingIcon = [4, 9, 12, 14, 15, 16, 18].includes(
		v.settings[0].rating_icon_type
	);
	let sessionIcons = {};
	const ratingIconType = settingData.rating_icon_type || 1;
	const badgeIconType = settingData.verified_icon_type || 1;
	const iconString = gsSession("get", "ttRatingIcons");
	if (iconString) {
		sessionIcons = JSON.parse(iconString);
		v.ratingIconSvg = sessionIcons["star" + ratingIconType];
		v.badgeIconSvg = sessionIcons["badge" + badgeIconType];
	}
	if (ratingIconType === 1) {
		v.ratingIconSvg = getSvg("star");
	}
	if (badgeIconType === 1) {
		v.badgeIconSvg = getSvg("verified");
	}

	if (!v.ratingIconSvg || !v.badgeIconSvg) {
		const url = "https://cdn.vstar.app/static/customer/icons.json";
		new Promise((res, rej) => {
			httpRequest({ url, res, rej }, true);
		})
			.then(d => {
				const ratingIconName = getRatingIconName(ratingIconType);
				const icons = JSON.parse(d);
				v.ratingIconSvg = icons[ratingIconName];
				v.badgeIconSvg = icons["badge" + badgeIconType];
				sessionIcons["star" + ratingIconType] = v.ratingIconSvg;
				sessionIcons["badge" + badgeIconType] = v.badgeIconSvg;
				gsSession("set", "ttRatingIcons", JSON.stringify(sessionIcons));
				$dqsa(".vstar-star .pending").forEach(it => {
					it.innerHTML = v.ratingIconSvg;
					it.classList.remove("pending");
				});
				$dqsa(".tt-swiper .user-verified").forEach(it =>
					it.insertAdjacentHTML("afterbegin", v.badgeIconSvg)
				);
			})
			.catch(() => {
				v.ratingIconSvg = getSvg("star");
				v.badgeIconSvg = getSvg("verified");
			});
	}
	const langReq = await request("getLang");

	v.lang = JSON.parse(langReq[1].lang_json);

	swiperStyle.textContent += `
	.tt-swiper {
			--column:${column};
			--common-height: ${commonHigh}px;
		}
	${v.customStyleCode}
	`;

	// if (sd.show_reviews === 1) {
	// 	requestOption.getReviews.params.is_show_all = 1;
	// } else if (sd.show_reviews === 2) {
	// 	requestOption.getReviews.params.is_feature = 1;
	// }
	// let revRed = await request("getReviews");
	// if (revRed[0]) {
	// 	return;
	// } else {
	// 	rd = revRed[1];
	// 	reviewsList = rd.list;
	// }
	// v.ratingData = rd.total_rating;
	// v.reviewsList = reviewsList;
	v.request = request;
	const carouselExecution = [
		setPrimaryCarousel,
		setTextCarousel,
		setPhotoCarousel,
		setBarrageCarousel,
	];

	// ## 嵌入轮播
	insertCarousels();
	async function insertCarousels() {
		const arr = [];
		v.settings.forEach(async (settings, index) => {
			arr.push(carouselExecution[index](settings, index));
		});
		if (arr.length) {
			insertReviewDetail();
		}
		requestSwiper().then(() => {
			let count = 0;
			arr.forEach((it, index) =>
				it.then(() => {
					count++;
					if (index === 3) {
						$dqsa(
							`.tt-carousel-row .tt-swiper-container[data-index="${index}"]`
						).forEach(wrapper => initSwiper(wrapper));
					} else {
						const wrapper = $dqs(`.tt-swiper-container[data-index="${index}"]`);
						if (wrapper) {
							initSwiper(wrapper);
						}
					}
					if (count === 4) {
						// 运行前执行用户需求自定义函数·
						if (typeof trustooAfterExecute !== "undefined") {
							trustooAfterExecute(v, undefined);
						}

						const onCarouselEmbed = new CustomEvent("onTTCarouselEmbed", {
							detail: {
								v,
							},
							bubbles: true,
							cancelable: true,
						});
						document.dispatchEvent(onCarouselEmbed);
					}
				})
			);
		});
	}

	function initSwiper(wrapper) {
		const maxColumns = v.settings[0].max_columns;
		const type = Number(wrapper.dataset.index) + 1;
		const index = wrapper.dataset.index;
		const cardCount = wrapper.dataset.count;
		let spaceBetween = 20,
			speed = v.normalSpeed;
		if (type === primaryType) {
			spaceBetween = 12;
		} else if (type === barrageType) {
			spaceBetween = 32;
			speed = v.barrageSpeed;
		}
		const breakpoints =
			type !== barrageType
				? {
						300: {
							slidesPerView: 1,
						},
						550: {
							slidesPerView: 2,
						},
						968: {
							slidesPerView: index == 0 ? maxColumns : 3,
						},
						1368: {
							slidesPerView: index == 0 ? maxColumns : 4,
						},
				  }
				: undefined;
		const autoplay =
			v.isSetTimer && cardCount > v.columns[index]
				? {
						delay: type === barrageType ? 0 : 3000,
						pauseOnMouseEnter: true,
				  }
				: false;
		new Swiper(wrapper.parentNode, {
			loop: true,
			speed,
			spaceBetween,
			slidesPerView: v.columns[index],
			autoplay,
			breakpoints,
			centerInsufficientSlides: [primaryType, barrageType].includes(type)
				? false
				: true,
			on: {
				afterInit: () => {
					if (index == 0) {
						const carousel = wrapper.closest("#tt-carousel");
						if (v.isProPage) {
							if (typeof Review === "undefined") {
								carousel.classList.remove("pending");
							} else {
								const handled = reviews => {
									if (reviews) {
										carousel.style.maxWidth = getComputedStyle(reviews).width;
									}
									carousel.classList.remove("pending");
								};
								const reviews = $dqs("#vstar-reviews,#noreviews-wrapper");
								if (Review.hasReviews === false) {
									handled(null);
								} else if (reviews) {
									handled(reviews);
								} else {
									document.addEventListener("onTrustooReviewsEmbed", () => {
										const reviews = $dqs("#vstar-reviews,#noreviews-wrapper");
										handled(reviews);
									});
								}
							}
						} else {
							carousel.classList.remove("pending");
						}
					} else {
						wrapper.closest("#tt-carousel").classList.remove("pending");
					}
				},
			},
		});
		setSwiperEvent(wrapper.parentNode, index);
	}

	const indexStyle = document.createElement("link");
	indexStyle.rel = "stylesheet";
	indexStyle.href = `https://${STATIC_BASE_URL}/static/css/index.css`;
	document.head.appendChild(indexStyle);

	v.arrangeCards = arrangeCards;

	//## 所有请求
	function request(key, otherParams) {
		let lang = "";
		const option = { ...requestOption[key] };
		const params = { ...option.params };
		if (typeof vstar_review_settings !== "undefined") {
			if (vstar_review_settings.auto_switch_language === 1) {
				lang = Shopify.locale ? Shopify.locale : "";
			} else if (vstar_review_settings.is_translate_review === 1) {
				lang = vstar_review_settings.language;
			}
		}
		if (key === "getReviews") {
			params.t = new Date().getTime();
			params.limit = 30;
			params.page = 1;
			params.sort_by = v.sortType;
			params.scene = 3;

			if (lang) {
				params.lang = lang;
			}
			Object.assign(params, otherParams);
			const productId =
				carouselScriptBlk && carouselScriptBlk.dataset.productId;
			if (v.isProductReviews && Number(productId)) {
				params.product_id = productId;
				params.is_show_all = 0;
			}
			option.params = params;
		} else if (key === "getLang") {
			if (lang) {
				option.params.lang = lang;
			} else if (v.settings[0].card_lang) {
				option.params.lang = v.settings[0].card_lang;
			}
		}

		return new Promise((res, rej) => {
			Object.assign(option, { res, rej });

			// if (ENV === "local") {
			// 	if (key === "getReviews") {
			// 		res(JSON.stringify(vstar_product_reviews));
			// 	}
			// 	// else if (key === "getSetting") {
			// 	// 	res(JSON.stringify(carouselSettings));
			// 	// }
			// 	else {
			// 		httpRequest(option);
			// 	}
			// } else
			//  {
			httpRequest(option);
			// }
		})
			.then(data => {
				data = JSON.parse(data);
				const code = data.code;
				if (code === -1) {
					throw new Error(data.message);
				}
				data = data.data;
				return [null, data];
			})
			.catch(err => {
				return [{ error: err }];
			});
	}
	function insertReviewDetail() {
		document.addEventListener("onTrustooDetailClose", function () {
			if (
				v.isSetTimer &&
				clickedSwiper &&
				clickedSwiper.params.autoplay.enabled
			) {
				clickedSwiper.autoplay.start();
				clickedSwiper = null;
			}
		});
		const detailScript = $dqs(".tt-review-detail-js");
		if (detailScript) {
			if (typeof TTRevDetail !== "undefined") {
				TTRevDetail.insertTTReviewDetail(v);
			} else {
				detailScript.onload = () => {
					TTRevDetail.insertTTReviewDetail(v);
				};
			}
		} else {
			const spt = document.createElement("script");
			let src = "";
			if (ENV === "local") {
				src = "../js/tt-review-detail.js?";
			} else {
				src = "https://" + STATIC_BASE_URL + "/static/js/tt-review-detail.js?";
			}
			src += v.constructTime;
			spt.setAttribute("src", src);
			spt.setAttribute("defer", true);
			spt.className = "tt-review-detail-js";
			spt.onload = () => {
				TTRevDetail.insertTTReviewDetail(v);
			};
			document.head.appendChild(spt);
		}
	}
	function requestSwiper() {
		const link = document.createElement("link");
		link.rel = "stylesheet";
		link.href = "https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.css";
		document.head.appendChild(link);
		return new Promise(res => {
			const script = document.createElement("script");
			script.setAttribute("defer", true);
			script.src =
				"https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.js";
			document.head.appendChild(script);
			script.onload = res;
		});
	}
})();

function handleCardClick(node, swiper, index) {
	document.body.classList.add("trustoo-open");
	const no = node.querySelector(".tt-card").getAttribute("no");
	v.revDetailInfo.no = no;
	v.revDetailInfo.imageIndex = 0;
	if (v.isSetTimer) {
		// destroyedTimer(index);
		clickedSwiper = swiper;
		swiper.autoplay.stop();
	}
	TTRevDetail.showTTReviewDetail(
		v,
		"",
		v.reviewsLists[index][no],
		"trustoo_swiper_review"
	);
}

// ## 循环切换卡片
function adjustCardPosition(swiper, curClientX) {
	// if (carouselType === barrageType) {
	// 	if (-currentTranslateX > tailCritical) {
	// 		const rows = swiper.children;
	// 		for (let index = 0; index < rows.length; index++) {
	// 			const row = rows[index];
	// 			const copy = row.children[0].cloneNode(true);
	// 			row.appendChild(copy);
	// 			row.children[0].remove();
	// 		}
	// 		currentTranslateX = -(tailCritical - cardWidth);
	// 		startX = curClientX - currentTranslateX;
	// 	}
	// } else {
	const index = swiper.dataset.index;
	const cardWidth = v.cardWidths[index];
	const tailCritical = v.tailX[index];
	let currentTranslateX = v.translateX[index];
	if (-currentTranslateX > tailCritical) {
		const copy = swiper.children[0].cloneNode(true);
		currentTranslateX = cardWidth - tailCritical;
		startX = curClientX - currentTranslateX;
		swiper.appendChild(copy);
		swiper.children[0].remove();
	} else if (currentTranslateX > 0) {
		const lastNode = Array.from(swiper.children).at(-1);
		const copy = lastNode.cloneNode(true);
		startX = curClientX + cardWidth;
		currentTranslateX = -cardWidth;
		lastNode.remove();
		swiper.insertAdjacentElement("afterbegin", copy);
	}
	v.translateX[index] = currentTranslateX;
	// }

	swiper.style.cssText = `transform:translate3d(${currentTranslateX}px,0,0);transition-duration: 0ms;`;
}

// ## 调整卡片列数
function arrangeCards() {
	arrangeColumn();

	cardWidth = parseInt(getComputedStyle(swiper).width) / column;
	otherCardWidth = parseInt(getComputedStyle(swiper).width) / otherColumn;
	v.cardWidth = cardWidth;
	tailCritical = cardWidth * (cardCount - column);
	if (cardCount > column) {
		if (v.isSetTimer) {
			if (!timer) {
				setTimer();
			}
		}
		wrapper.classList.remove("hide-btn");
	} else {
		if (v.isSetTimer) {
			destroyedTimer();
		}
		wrapper.classList.add("hide-btn");
	}
	scrollSwiper();
	let curCount = Math.round(Math.abs(currentTranslateX) / cardWidth);
	if (curCount >= cardCount - column) {
		curCount = cardCount - column;
	}
	currentTranslateX = -(curCount * cardWidth);
	swiper.style.cssText = `transform:translate3d(${currentTranslateX}px, 0, 0);transition-duration:500ms`;
}

export function arrangeColumn() {
	const width = window.innerWidth;
	let column,
		oColumn = 0;
	if (width <= 550) {
		oColumn = column = 1;
	} else if (width >= 550 && width < 968) {
		oColumn = column = 2;
	} else if (width >= 968 && width < 1368) {
		column = v.settings[0].max_columns;
		oColumn = 3;
	} else if (width >= 1368) {
		column = v.settings[0].max_columns;
		oColumn = 4;
	}
	v.columns = [column, oColumn, oColumn, 4];
}

// ## 卡片轮播
function scrollSwiper(swiper, type) {
	let temp = null;
	let currentTranslateX = v.translateX[type];
	const tailCritical = v.tailX[type];
	if (-currentTranslateX > tailCritical) {
		temp = -tailCritical;
		adjustCardPosition(swiper);
	} else if (currentTranslateX > 0) {
		temp = 0;
		adjustCardPosition(swiper);
	} else {
		swiper.style.cssText = `transform:translate3d(${currentTranslateX}px,0,0);`;
	}

	if (temp !== null) {
		currentTranslateX = temp;
		setTimeout(() => {
			swiper.style.cssText = `transition-duration: ${duration}ms;transform:translate3d(${currentTranslateX}px,0,0);`;
			v.translateX[type] = currentTranslateX;
		});
	}
	toggleTextCarouselBtn();
}

function toggleBtn(swiper, index, directionType) {
	const cardWidth = v.cardWidths[index];
	const x = v.translateX;
	if (directionType === toLeftType) {
		x[index] += cardWidth;
		scrollSwiper(swiper, index);
	} else if (directionType === toRightType) {
		x[index] -= cardWidth;
		scrollSwiper(swiper, index);
	}
}

/**
 * ## 确定各轮播嵌入的元素
 * @param {number} selector 位置选择器
 * @param {number} homeActiveVal 对应轮播首页开关的值
 * @param {number} proActiveVal 对应轮播产品页开关的值
 * @returns {HTMLElement} 选中的单个元素
 */
export function getCarouselEmbedEle(selector, homeActiveVal, proActiveVal) {
	const themeBlock = Array.from($dqsa(selector)).find(it => it.offsetParent);
	let isBackendActive = false;
	if (themeBlock) {
		return themeBlock;
	}
	const isHomeActive = homeActiveVal === 1,
		isProActive = proActiveVal === 1;
	if (isHomeActive && v.isHomePage) {
		isBackendActive = true;
	} else if (isProActive && v.isProPage) {
		isBackendActive = true;
	}

	if (!isBackendActive) {
		return null;
	}

	if (v.wrapper) {
		return v.wrapper;
	}

	let position = "beforebegin";
	let targetEle = null;

	let targetCustomInfo = null;
	if (
		typeof vstar_custom_info !== "undefined" &&
		vstar_custom_info.custom_carousel_info
	) {
		targetCustomInfo = vstar_custom_info.custom_carousel_info.list.find(
			it =>
				(typeof it.theme_id === "undefined" || it.theme_id === v.themeId) &&
				it.page.indexOf(pageType) !== -1
		);
		if (targetCustomInfo) {
			targetEle = $dqs(targetCustomInfo.selector);
			position = targetCustomInfo.position;
		}
	}
	if (v.isProPage && !targetEle) {
		const info = getCarouselProPageEmbedEle();
		targetEle = info.targetEle;
		position = info.position;
	}
	if (!targetEle) {
		let footer = $dqs("#shopify-section-footer,footer"),
			main = $dqs("main"),
			mainTemplate = $dqs(
				'.shopify-section[id$="__main"],#shopify-section-product-template'
			);

		if (mainTemplate) {
			targetEle = mainTemplate;
			position = "afterend";
		} else if (main) {
			if (main.contains(footer)) {
				targetEle = footer;
			} else {
				targetEle = main;
				position = "afterend";
			}
		}
		if (footer) {
			if (targetEle) {
				if (v.isHomePage) {
					targetEle = footer;
					position = "beforebegin";
				}
			} else {
				targetEle = footer;
			}
		}
	}
	if (targetEle) {
		targetEle.insertAdjacentHTML(
			position,
			`<div id="tt-carousels-wrapper"></div>`
		);
		v.wrapper = $dqs("#tt-carousels-wrapper");
		return v.wrapper;
	}
}

/**
 * ## 确定评论区的嵌入的元素
 * @returns {{targetEle:HTMLElement,position:string}} 选中的单个元素
 */
function getCarouselProPageEmbedEle() {
	let position = "beforebegin";
	let targetEle = null;
	const reviews = $dqs("#reviews-wrapper.trustoo-reviews-wrapper");
	if (reviews) {
		targetEle = reviews;
	} else {
		const reviewsBlock = Array.from(
			$dqsa(
				".pagefly-review-widget-sealapp,.custom-vstar-review-widget,#seal-review-widget"
			)
		).find(it => it.offsetParent);
		if (reviewsBlock) {
			targetEle = reviewsBlock;
		}

		let targetCustomInfo = null;
		if (
			typeof vstar_custom_info !== "undefined" &&
			vstar_custom_info.get_move_review_info
		) {
			targetCustomInfo = vstar_custom_info.get_move_review_info.list.find(
				it =>
					(typeof it.theme_id === "undefined" || it.theme_id === v.themeId) &&
					it.page.indexOf(pageType) !== -1
			);
			if (targetCustomInfo) {
				targetEle = $dqs(targetCustomInfo.selector);
				position = targetCustomInfo.position;
			}
		}
	}
	return { targetEle, position };
}

function toggleTextCarouselBtn() {
	const cardWidth = v.cardWidths[3];
	const no =
		swiper.children[
			-currentTranslateX / cardWidth + column - 1
		].firstElementChild.getAttribute("no");
	$dqs("#tt-carousel .tt-swiper-ring.active").classList.remove("active");
	if (no === "0") {
		$dqs("#tt-carousel .tt-swiper-ring:first-child").classList.add("active");
	} else if (no == cardCount - 1) {
		$dqs("#tt-carousel .tt-swiper-ring:last-child").classList.add("active");
	} else {
		$dqs("#tt-carousel .tt-swiper-ring.middle").classList.add("active");
	}
}
/**
 * document.querySelector
 * @param {number} selector css选择器
 * @param {HTMLElement} context 上下文
 * @returns {HTMLElement} 选中的单个元素
 */
export function $dqs(selector, context) {
	let node;
	if (context) {
		node = context.querySelector(selector);
	} else {
		node = document.querySelector(selector);
	}
	return node;
}

/**
 * document.querySelectorAll
 * @param {number} selector css选择器
 * @param {HTMLElement} context 上下文
 * @returns {HTMLElement} 选中元素集合
 */
export function $dqsa(selector, context) {
	let nodes;
	if (context) {
		nodes = context.querySelectorAll(selector);
	} else {
		nodes = document.querySelectorAll(selector);
	}
	return nodes;
}

function getRatingIconName(type) {
	const codes = [
		"star",
		"rounded",
		"spikes",
		"surrounded",
		"fire",
		"heart",
		"diamond",
		"crown",
		"trophy",
		"shirt",
		"skirt",
		"bag",
		"tie",
		"cup",
		"leaf",
		"seedling",
		"footprint",
		"chef",
	];
	return codes[type - 1];
}

function checkMobile() {
	let ua = navigator.userAgent,
		isAndroid = /(?:Android)/.test(ua),
		isFireFox = /(?:Firefox)/.test(ua),
		isTablet =
			/(?:iPad|PlayBook)/.test(ua) ||
			(isAndroid && !/(?:Mobile)/.test(ua)) ||
			(isFireFox && /(?:Tablet)/.test(ua)),
		isPhone = /(?:iPhone)/.test(ua) && !isTablet;
	return isAndroid || isPhone;
}

// 封装xhr请求
function httpRequest(option, isStatic = false) {
	const { params, method = "GET" } = option;
	const success = option.res,
		fail = option.rej;
	// 获取xmlHttpRequest对象
	let xmlHttp = new XMLHttpRequest();
	// API路由
	// pro  api.trustoo.io
	// test rtest.sealapps.com
	// dev rtest.sealapps.com
	var baseUrl = "https://" + BASE_URL + "/api/v1/reviews/";
	let url = isStatic ? option.url : baseUrl + option.url;
	let paramsStr = "";
	if (typeof params === "object") {
		paramsStr = Object.keys(params).reduce(
			(pre, item) => pre + "&" + item + "=" + params[item],
			""
		);
	}

	// post请求方式
	if (method == "POST") {
		xmlHttp.open(method, url, true);
		// 添加http头，发送信息至服务器时的内容编码类型
		// xmlHttp.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
		// 发送数据，请求体数据
		// xmlHttp.send(`product_id=${params.product_id}&shop_id=${params.shop_id}`);
		if (paramsStr && !isOss) {
			paramsStr = paramsStr.slice(1);
		} else {
			paramsStr = params;
		}
		// 发送数据
		xmlHttp.send(paramsStr);
	} else if (method == "GET") {
		if (paramsStr) {
			paramsStr = paramsStr.replace("&", "?");
			url += paramsStr;
		}
		xmlHttp.open(method, url, true);
		xmlHttp.send();
	}
	xmlHttp.onreadystatechange = () => {
		if (xmlHttp.readyState == 4) {
			let resData = xmlHttp.responseText;
			if (xmlHttp.status == 200 || xmlHttp.status == 203)
				success && success(resData);
			else
				fail && fail({ responseText: resData, statusText: xmlHttp.statusText });
		}
	};
}

function gsSession(method, name, data) {
	if (method === "set") {
		try {
			sessionStorage.setItem(name, JSON.stringify(data));
		} catch (error) {}
	} else {
		return JSON.parse(sessionStorage.getItem(name));
	}
}
// ## 设置定时器
export function setTimer(swiper, index) {
	let timer = v.timers[index];
	if (
		timer === null &&
		$dqs("#trustoo-mask") &&
		getComputedStyle($dqs("#trustoo-mask")).display === "none"
	) {
		timer = setInterval(() => {
			if (canMoveType) {
				toggleBtn(swiper, index, toRightType);
			} else {
				// setBarrageScroll(swiper);
			}
		}, 3000);
		v.timers[index] = timer;
	}
}
function destroyedTimer(index) {
	const timer = v.timers[index];
	if (timer) {
		clearInterval(timer);
		v.timers[index] = null;
	}
}

const showReviewsClick = new CustomEvent("onTTshowReviewsClick", {
	detail: {
		v,
	},
	bubbles: true,
	cancelable: true,
});
// ## 设置轮播事件
export function setSwiperEvent(container, carouselIndex) {
	const wrapper = container.closest("#tt-carousel");

	const swiper = container.swiper;
	const type = Number(carouselIndex) + 1;
	if ([primaryType, photoType].includes(type)) {
		const preBtn = $dqs(".tt-swiper-pre", wrapper);
		const nextBtn = $dqs(".tt-swiper-next", wrapper);
		if (preBtn) {
			preBtn.onclick = function () {
				swiper.slidePrev();
			};
		}
		if (nextBtn) {
			nextBtn.onclick = function () {
				swiper.slideNext();
			};
		}
	} else if (type === textType) {
		const ratingsWrapper = $dqs("#tt-swiper-ring-wrapper");
		const ratings = ratingsWrapper.children;
		if (ratings.length === 3) {
			ratings[0].onclick = () => {
				swiper.slidePrev();
			};
			ratings[2].onclick = () => {
				swiper.slideNext();
			};
			swiper.on("slideChangeTransitionEnd", function () {
				const endIndex = this.slides.length - 1;
				$dqs(".tt-swiper-ring.active", ratingsWrapper).classList.remove(
					"active"
				);

				const curEndIndex =
					(this.realIndex + this.params.slidesPerView - 1) % this.slides.length;

				if (curEndIndex === endIndex) {
					ratings[2].classList.add("active");
				} else if (curEndIndex === 0) {
					ratings[0].classList.add("active");
				} else {
					ratings[1].classList.add("active");
				}
			});
		}
	}

	const descNode = $dqs("#tt-reviews-num[data-total-reviews]", wrapper);
	if (descNode) {
		descNode.style.cssText = "text-decoration: underline;cursor: pointer;";
		descNode.onclick = () => {
			document.dispatchEvent(showReviewsClick);
		};
	}

	swiper.on("click", s => {
		if (!s.clickedSlide) {
			return;
		}
		handleCardClick(s.clickedSlide, swiper, carouselIndex);
	});
}
export function getSvg(type, color = "#000") {
	let svg = "";
	switch (type) {
		case "verified": {
			svg = `<svg class="trustoo-badge-icon" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM5.30236 10.7724C5.45276 10.9239 5.64913 11 5.84629 11C6.04267 11 6.23982 10.9239 6.39022 10.7724L11.775 5.32765C12.075 5.02399 12.075 4.532 11.775 4.22834C11.4742 3.92389 10.9871 3.92389 10.6871 4.22834L5.84629 9.1223L3.31288 6.56115C3.01286 6.25748 2.52581 6.25748 2.22501 6.56115C1.925 6.86481 1.925 7.35758 2.22501 7.66125L5.30236 10.7724Z" fill="${color}"/>
			</svg>`;
			break;
		}
		case "star": {
			svg = `<svg class="trustoo-rating-icon" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M10 0L12.8214 6.11672L19.5106 6.90983L14.5651 11.4833L15.8779 18.0902L10 14.8L4.12215 18.0902L5.43493 11.4833L0.489435 6.90983L7.17863 6.11672L10 0Z"/>
			</svg>
			`;
			break;
		}
		case "scrollBtn": {
			svg = `<svg xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 10 18" fill="none">
			<path d="M8.92884 18C8.65455 18 8.38022 17.8949 8.17094 17.6839L0.313949 9.76416C-0.10465 9.34296 -0.10465 8.65889 0.313949 8.23695L8.17094 0.316457C8.58954 -0.105486 9.26745 -0.105486 9.68605 0.316457C10.1046 0.737664 10.1046 1.42173 9.68605 1.84367L2.58696 9.00017L9.68605 16.156C10.1046 16.5779 10.1046 17.2612 9.68605 17.6832C9.47746 17.8949 9.20313 18 8.92884 18Z" fill="${color}"/>
			</svg>
		`;
			break;
		}
		case "next": {
			svg = `<svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M1.23193 0.363281C1.45136 0.363281 1.67082 0.445047 1.83825 0.60912L8.12384 6.76893C8.45872 7.09654 8.45872 7.62859 8.12384 7.95676L1.83825 14.1171C1.50337 14.4453 0.961039 14.4453 0.62616 14.1171C0.291281 13.7895 0.291281 13.2575 0.62616 12.9293L6.30543 7.36315L0.626159 1.79753C0.29128 1.46935 0.29128 0.937871 0.626158 0.609693C0.79303 0.445047 1.01249 0.363281 1.23193 0.363281Z" fill="${color}"/>
		</svg>
		`;
			break;
		}
		case "comma": {
			svg = `<svg xmlns="http://www.w3.org/2000/svg" width="29" height="20" viewBox="0 0 29 20" fill="none">
			<path d="M12.6619 7.75529C12.6619 9.87765 12.3351 11.7007 11.6818 13.2245C11.0283 14.8028 10.184 16.0816 9.14938 17.0612C8.11434 18.0953 6.94373 18.8299 5.63661 19.2652C4.27502 19.7553 2.94079 20 1.6339 20V17.1428C3.04972 17.1428 4.38395 16.6529 5.63661 15.6734C6.8348 14.7482 7.54259 13.4694 7.76068 11.8367C7.59706 11.8911 7.40655 11.9456 7.1887 12C7.00025 12.0616 6.80954 12.1161 6.61695 12.1633C6.34463 12.2176 6.04497 12.2449 5.71819 12.2449C4.08428 12.2449 2.72293 11.6191 1.6339 10.3673C0.544634 9.17012 0 7.75529 0 6.12259C0 4.48965 0.571748 3.04753 1.71548 1.796C2.80475 0.598588 4.27502 0 6.12678 0C8.19615 0 9.80271 0.761882 10.9464 2.28565C12.0899 3.864 12.6619 5.68706 12.6619 7.75506V7.75529ZM29 7.75529C29 9.87765 28.6732 11.7007 28.0197 13.2245C27.3661 14.8028 26.522 16.0816 25.4872 17.0612C24.4527 18.0953 23.2816 18.8299 21.9747 19.2652C20.6131 19.7553 19.2789 20 17.9717 20V17.1428C19.3878 17.1428 20.722 16.6529 21.9747 15.6734C23.1727 14.7482 23.8807 13.4694 24.0985 11.8367C23.9351 11.8911 23.7446 11.9456 23.5268 12C23.3383 12.0616 23.1476 12.1161 22.955 12.1633C22.6827 12.2176 22.383 12.2449 22.0563 12.2449C20.4224 12.2449 19.061 11.6191 17.9717 10.3673C16.8825 9.17012 16.3378 7.75506 16.3378 6.12259C16.3378 4.48965 16.9098 3.04753 18.0536 1.796C19.1428 0.598588 20.6131 0 22.4649 0C24.5342 0 26.1408 0.761882 27.2845 2.28565C28.4283 3.864 29 5.68706 29 7.75529Z" fill="${color}"/>
			</svg>`;
			break;
		}
		case "noImage": {
			svg = `<svg viewBox="0 0 20 20" style="width:60px;height:60px;fill:#8A8A8A" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true"><path d="M12.5 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"></path><path fill-rule="evenodd" d="M9.018 3.5h1.964c.813 0 1.469 0 2 .043.546.045 1.026.14 1.47.366a3.75 3.75 0 0 1 1.64 1.639c.226.444.32.924.365 1.47.043.531.043 1.187.043 2v1.964c0 .813 0 1.469-.043 2-.045.546-.14 1.026-.366 1.47a3.75 3.75 0 0 1-1.639 1.64c-.444.226-.924.32-1.47.365-.531.043-1.187.043-2 .043h-1.964c-.813 0-1.469 0-2-.043-.546-.045-1.026-.14-1.47-.366a3.75 3.75 0 0 1-1.64-1.639c-.226-.444-.32-.924-.365-1.47-.043-.531-.043-1.187-.043-2v-1.964c0-.813 0-1.469.043-2 .045-.546.14-1.026.366-1.47a3.75 3.75 0 0 1 1.639-1.64c.444-.226.924-.32 1.47-.365.531-.043 1.187-.043 2-.043Zm-1.877 1.538c-.454.037-.715.107-.912.207a2.25 2.25 0 0 0-.984.984c-.1.197-.17.458-.207.912-.037.462-.038 1.057-.038 1.909v1.428l.723-.867a1.75 1.75 0 0 1 2.582-.117l2.695 2.695 1.18-1.18a1.75 1.75 0 0 1 2.604.145l.216.27v-2.374c0-.852 0-1.447-.038-1.91-.037-.453-.107-.714-.207-.911a2.25 2.25 0 0 0-.984-.984c-.197-.1-.458-.17-.912-.207-.462-.037-1.056-.038-1.909-.038h-1.9c-.852 0-1.447 0-1.91.038Zm-2.103 7.821a7.12 7.12 0 0 1-.006-.08.746.746 0 0 0 .044-.049l1.8-2.159a.25.25 0 0 1 .368-.016l3.226 3.225a.75.75 0 0 0 1.06 0l1.71-1.71a.25.25 0 0 1 .372.021l1.213 1.516c-.021.06-.045.114-.07.165-.216.423-.56.767-.984.983-.197.1-.458.17-.912.207-.462.037-1.056.038-1.909.038h-1.9c-.852 0-1.447 0-1.91-.038-.453-.037-.714-.107-.911-.207a2.25 2.25 0 0 1-.984-.984c-.1-.197-.17-.458-.207-.912Z"></path></svg>`;
			break;
		}
		case "video": {
			svg = `<svg  class="tt-video-icon"  xmlns="http://www.w3.org/2000/svg" width="49" height="48" viewBox="0 0 49 48" fill="none">
			<g filter="url(#filter0_d_7148_16158)">
			<path d="M24.5 4C13.4723 4 4.5 12.9723 4.5 24C4.5 35.0277 13.4723 44 24.5 44C35.5277 44 44.5 35.0277 44.5 24C44.5 12.9723 35.5277 4 24.5 4ZM24.5 40.9231C15.1692 40.9231 7.57692 33.3308 7.57692 24C7.57692 14.6692 15.1692 7.07692 24.5 7.07692C33.8308 7.07692 41.4231 14.6692 41.4231 24C41.4231 33.3308 33.8308 40.9231 24.5 40.9231ZM22.3277 15.0077C22.0982 14.8408 21.8269 14.7407 21.544 14.7184C21.2611 14.6961 20.9776 14.7525 20.7247 14.8813C20.4719 15.0101 20.2596 15.2063 20.1113 15.4483C19.963 15.6903 19.8846 15.9685 19.8846 16.2523V32.5877C19.8849 32.8713 19.9635 33.1494 20.1119 33.3911C20.2603 33.6329 20.4726 33.8289 20.7254 33.9575C20.9782 34.0862 21.2617 34.1424 21.5444 34.12C21.8272 34.0977 22.0983 33.9976 22.3277 33.8308L33.5585 25.6631C33.7549 25.5204 33.9147 25.3332 34.0249 25.1169C34.1351 24.9006 34.1926 24.6612 34.1926 24.4185C34.1926 24.1757 34.1351 23.9364 34.0249 23.72C33.9147 23.5037 33.7549 23.3166 33.5585 23.1738L22.3277 15.0077Z" fill="white"/>
			</g>
			<defs>
			<filter id="filter0_d_7148_16158" x="0.5" y="0" width="48" height="48" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
			<feFlood flood-opacity="0" result="BackgroundImageFix"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset/>
			<feGaussianBlur stdDeviation="2"/>
			<feComposite in2="hardAlpha" operator="out"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
			<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7148_16158"/>
			<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7148_16158" result="shape"/>
			</filter>`;
			break;
		}
	}
	return svg;
}
//Mark 构造星星
export function getStars(rating, v) {
	// size参数有值，说明是要用svg星星图标
	const icon = v.ratingIconSvg;
	let extraCls = "";
	if (!icon) {
		extraCls = " pending";
	}
	const starString = `<div class="star-item${extraCls}">${icon}</div>`;
	const nostarString = `<div class="star-item nostar${extraCls}">${icon}</div>`;
	if (!parseInt(rating)) rating = 0;
	var starTag = "";
	var starNum = Math.floor(rating);

	for (var i = 0; i < starNum; i++) {
		starTag += starString;
	}
	if (rating > starNum) {
		const p = ((rating - starNum) / 1).toFixed(2) * 100;
		starTag += `<div class="star-item half"><div class="trustoo-swiper-nostar${extraCls}">${icon}</div><div style="width:${iconShowRatioAdjustment(
			p,
			v.settings[0].rating_icon_type
		)}%" class="trustoo-swiper-star${extraCls}">${icon}</div></div>`;
		starNum++;
	}
	for (var i = starNum; i < 5; i++) {
		starTag += nostarString;
	}
	return starTag;
}
export function iconShowRatioAdjustment(percentage, type) {
	let temp = parseInt(percentage) / 100;
	const ratio = parseInt(percentage) / 100;
	switch (type) {
		case 13: {
			temp = 0.28 + parseFloat((0.44 * ratio).toFixed(2));
			break;
		}
		case 15: {
			temp = 0.215 + parseFloat((0.57 * ratio).toFixed(2));
			break;
		}
	}
	temp = temp * 100;
	return temp;
}
export function getSettingsStyle(settings, type) {
	const s = settings;
	return `
#tt-carousel[data-type=${type}]{
	--card-bg-color: ${
		s.is_show_card == 1 ? s.widget_layout_card_bg_color : "none"
	};
	--text-color:${s.widget_layout_text_color};
	--badge-color:${s.widget_layout_badge_color};
	--button-color:${s.scroll_buttons_icon_color};
}
${s.font ? `#tt-carousel[data-type=${type}] *{font-family: ${s.font}}` : ""}
#tt-carousel[data-type=${type}] .vstar-star{
  --star-color:${s.widget_layout_star_color};
  --nostar-color:${s.widget_layout_disabled_star_color};
}
#trustoo-review-detail{
	--star-color:${s.widget_layout_star_color};
--nostar-color:${s.widget_layout_disabled_star_color};
}`;
}
// function getTail(cardWidth, containerWidth) {
// 	console.log("cardCount", cardCount);
// 	return (
// 		Math.ceil(
// 			(cardWidth * Math.floor(cardCount / 2) - containerWidth) / cardWidth
// 		) * cardWidth
// 	);
// }
